.mainContainer {
  margin: 6em 0 0 0;
  z-index: 1;
  border-radius: 20px;
  background: rgb(89, 53, 126);
  background: linear-gradient(45deg, rgba(89, 53, 126, 1) 0%, rgba(52, 114, 251, 1) 100%);
  position: relative;
}

.backCard {
  background: #000;
  z-index: 2;
  margin: 1.5px;
  border-radius: 20px;
}

.card {
  background: rgb(52, 114, 251);
  background: linear-gradient(132deg, rgba(52, 114, 251, 0.3) 0%, rgba(52, 114, 251, 0.1) 100%);
  padding: 16px;
  z-index: 3;
  border-radius: 20px;
}

.ExBtn {
  padding: 5px 10px;
  background-color: rgba(58, 138, 202, 0.2);
  border: 1px solid #3472FB;
  color: #fff;

  &:hover {
    background-color: rgba(58, 138, 202, 0.5);
    color: #fff;
  }
}

.changeText {
  position: absolute;
  background: rgb(52, 114, 251);
  top: -30px;
  padding: 7px 15px 25px 15px;
  border-radius: 15px 15px 0;
}