@import "../../styles/colors.module";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";

.homeContainer {
  background-image: url("../../../public/img/bg-sample2.png");
  background-color: unset;
}

.container {
  margin-top: 25px;
  margin-bottom: 50px;
}

.priceLabel {
  text-align: right;
  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

.layout {
  background-color: unset;
  //overflow: hidden;
  display: flex;
  height: 100%;
  position: relative;
}

.side {
  background-color: $dark-color !important;
  border-right: 1px solid $gray-700;
  z-index: 4;
  position: fixed !important;
  left: 0;
}

.sideItem {
  transition: color .20s ease-in-out;
}

.userLevel {
  width: 35px;
  height: 35px;
  right: 10%;
  bottom: 5%;
  z-index: 1;
  border-radius: 50%;
}

.authentication {
  animation: bounceTop01 7s ease infinite;
}

@keyframes bounceTop01 {
  0%, 100% {
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
  }
}

.msgCount {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: $red;
  top: -11px;
  right: 33px;
  color: $white;
}

.authenticationNotice {
  bottom: 15px;
  width: 53%;
  right: 22%;

  &::after {
    content: '';
    top: 0;
    transform: translateX(100%);
    width: 34%;
    height: 100%;
    position: absolute;
    z-index: 1;
    animation: slide 5s infinite;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.1) 50%, rgba(125, 185, 232, 0) 85%)
  }

  /* animation */

  @keyframes slide {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}