@import "../../styles/colors.module";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";

.container {
  margin: 6em 0 0 0;
  z-index: 3;
  @include media-breakpoint-down(md) {
    margin: 3em 0 0 0;
  }
}

.startNow {
  border: 1px solid #fff;
  padding: 5px;
  background-color: #262626;
  border-radius: 10px;

  input::placeholder {
    color: #f0f0f0;
  }
}

.img {
  position: absolute;
  left: 0;
  z-index: 2;
}

.gradiant2 {
  background: radial-gradient(circle, rgba(180, 94, 255, 1) 0%, rgba(180, 94, 255, 0.01) 60%, rgba(180, 94, 255, 0) 100%);
  position: absolute;
  top: 9%;
  left: 17%;
  min-height: 700px;
  min-width: 700px;
  border-radius: 50%;
  opacity: 0.3;
  z-index: 1;
  @include media-breakpoint-down(md) {
    min-height: 400px;
    min-width: 400px;
    top: -5%;
    left: -2%;
  }
}

.gradiant {
  background: radial-gradient(circle, rgba(52, 114, 251, 0.9) 0%, rgba(81, 135, 253, 0.3) 40%, rgba(104, 151, 255, 0.001) 75%);
  position: absolute;
  top: -5%;
  right: 20%;
  min-height: 700px;
  min-width: 700px;
  border-radius: 50%;
  opacity: 0.3;
  z-index: 1;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.collapse {
  margin-bottom: 12px;
  background: linear-gradient(45deg, rgba(52, 114, 251, 0.2) 0%, rgba(52, 114, 251, 0) 70%);
  border: 1px solid #404040 !important;
  border-radius: 20px !important;
}

.polygon1 {
  margin-top: 40px;
  z-index: 1;
  @include media-breakpoint-down(xxl) {
    margin-top: 75px;
  }

  @include media-breakpoint-down(xl) {
    margin-top: 65px;
  }

  @include media-breakpoint-down(lg) {
    margin-top: 40px;
  }
}

.polygon2 {
  margin-top: 55px;
  z-index: 1;
  @include media-breakpoint-down(xxl) {
    margin-top: 30px;
  }

  @include media-breakpoint-down(xl) {
    margin-top: 30px;
  }

  @include media-breakpoint-down(lg) {
    margin-top: 40px;
  }
}

.polygon3 {
  margin-top: 145px;
  z-index: 1;
  @include media-breakpoint-down(xxl) {
    margin-top: 70px;
  }

  @include media-breakpoint-down(xl) {
    margin-top: 30px;
  }

  @include media-breakpoint-down(lg) {
    margin-top: 40px;
  }
}

.polygon4 {
  margin-top: 95px;
  z-index: 1;
  @include media-breakpoint-down(xxl) {
    margin-top: 135px;
  }

  @include media-breakpoint-down(xl) {
    margin-top: 95px;
  }

  @include media-breakpoint-down(lg) {
    margin-top: 40px;
  }
}

.polygon {
  //box-shadow: 0px 0px 100px 0px rgba(255, 183, 71, 1);
}

.step {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.step1 {
  right: -16px;
  bottom: 35%;
}

.step2 {
  right: -16px;
  top: 18%;
}

.step3 {
  left: -16px;
  bottom: 35%;
}

.step4 {
  left: -16px;
  top: 18%;
}

.videoContainer {
  border: 3px solid #404040;
  border-radius: 20px;
  height: 300px;
}

.faqBullets{
  filter: drop-shadow(0 0 0.7rem #3472FB);
}

.listGroupItem{
  list-style-type: none;
}