@import "../../../styles/colors.module";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";

.mainContainer {
  border: 2px solid #404040;
  background: rgb(30, 199, 95);
  background: linear-gradient(45deg, rgba(30, 199, 95, 0.3) 0%, rgba(30, 199, 95, 0) 100%);
  border-radius: 30px;
  margin: 6em 0 0 0;
  z-index: 5;
  position: relative;
}

.section2 {
  border-right: 3px dashed #838383;

  @include media-breakpoint-down(lg) {
    border-top: 3px dashed #838383;
    border-right: none;
  }
}