@import "../../styles/colors.module";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";

.container {
  margin: 6em 0 0 0;
  z-index: 3;
  @include media-breakpoint-down(md) {
    margin: 3em 0 0 0;
  }
}

.gradiant {
  background: radial-gradient(circle, rgba(52, 114, 251, 0.9) 20%, rgba(81, 135, 253, 0.3) 40%, rgba(104, 151, 255, 0.001) 75%);
  position: absolute;
  top: 9%;
  left: 15%;
  min-height: 700px;
  min-width: 700px;
  border-radius: 50%;
  opacity: 0.3;
  z-index: 1;
  @include media-breakpoint-down(md) {
    min-height: 400px;
    min-width: 400px;
    top: -5%;
    left: -2%;
  }
}

.gradiant2 {
  background: radial-gradient(circle, rgba(180, 94, 255, 1) 0%, rgba(180, 94, 255, 0.01) 60%, rgba(180, 94, 255, 0) 100%);
  position: absolute;
  top: -5%;
  left: 48%;
  min-height: 700px;
  min-width: 700px;
  border-radius: 50%;
  opacity: 0.3;
  z-index: 1;
  @include media-breakpoint-down(md) {
    min-height: 400px;
    min-width: 400px;
    top: -5%;
    left: -2%;
  }
}

.startNow {
  border: 1px solid #fff;
  padding: 5px;
  background-color: #262626;
  border-radius: 10px;

  input::placeholder {
    color: #f0f0f0;
  }
}

.fastAccess {
  background: linear-gradient(90deg, rgba(52, 114, 251, 0) 0, rgba(52, 114, 251, 0.15) 80%) !important;
  border-radius: 20px;
  border: 1px solid #404040;
}

.centerGradiant2 {
  background: radial-gradient(circle, rgb(118, 99, 219) 0%, rgba(118, 99, 219, 0.4) 40%, rgba(118, 99, 219, 0) 71%);
  position: absolute;
  top: 93%;
  left: 3%;
  min-height: 700px;
  min-width: 700px;
  border-radius: 50%;
  opacity: 0.3;
  @include media-breakpoint-down(md) {
    display: none;
  }
}