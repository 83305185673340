@import "colors.module";

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 8px;
  text-transform: none;
  transition: color .20s ease-in-out, background-color .20s ease-in-out, border-color .20s ease-in-out, box-shadow .20s ease-in-out;
}

.btnPrimary {
  color: #fff;
  background-color: $primary-color;

  &:hover {
    background-color: $tertiary-color;
    border: 1px solid $primary-color;
    color: #fff;
  }
}

.btnOutlinePrimary {
  border: 1px solid $primary-color;
  color: #fff;
  background-color: $tertiary-color;

  &:hover {
    background-color: $primary-color;
    border: 1px solid $tertiary-color;
    color: #fff;
  }
}

.btnSecondary {
  color: #fff;
  background-color: $secondary-color;

  &:hover {
    background-color: $tertiary-color;
    border: 1px solid $secondary-color;
    color: #fff;
  }
}

.btnTertiary {
  color: #fff;
  background-color: $tertiary-light-color;

  &:hover {
    background-color: $tertiary-color;
    border: 1px solid $tertiary-light-color;
    color: #fff;
  }
}

.btnRadius {
  border-radius: 20px;
}

button.navbar-brand, button.nav-link {
  background-color: unset;
}