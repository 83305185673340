@import "../../styles/colors.module";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";

.topLeftGradiant {
  background: radial-gradient(circle, rgba(180, 94, 255, 0.7) 0%, rgba(180, 94, 255, 0.1) 40%, rgba(180, 94, 255, 0) 75%);
  position: absolute;
  top: -15%;
  left: -15%;
  min-height: 700px;
  min-width: 700px;
  border-radius: 50%;
  opacity: 0.3;

  @include media-breakpoint-down(md) {
    top: -10%;
    left: 0%;
    min-height: 300px;
    min-width: 300px;
  }
}

.topRightGradiant {
  background: radial-gradient(circle, rgba(52, 114, 251, 0.9) 0%, rgba(52, 114, 251, 0.2) 50%, rgba(52, 114, 251, 0) 70%);
  position: absolute;
  top: -15%;
  right: -13%;
  min-height: 700px;
  min-width: 700px;
  border-radius: 50%;
  opacity: 0.3;

  @include media-breakpoint-down(md) {
    top: -35%;
    right: -110%;
  }
}

.shape1 {
  position: absolute;
  top: 28%;
  left: 15%;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.shape2 {
  position: absolute;
  top: 4%;
  right: 9%;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.whyBitBlexGradiant1 {
  background: radial-gradient(circle, rgba(118, 99, 219, 1) 0%, rgba(118, 99, 219, 0.3) 45%, rgba(118, 99, 219, 0) 70%);
  position: absolute;
  top: 55%;
  left: 25%;
  min-height: 500px;
  min-width: 500px;
  border-radius: 50%;
  opacity: 0.3;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.whyBitBlexGradiant2 {
  background: radial-gradient(circle, rgba(118, 99, 219, 1) 0%, rgba(118, 99, 219, 0.3) 45%, rgba(118, 99, 219, 0) 70%);
  position: absolute;
  top: 73%;
  right: 12%;
  min-height: 600px;
  min-width: 600px;
  border-radius: 50%;
  opacity: 0.3;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.helpGradiant {
  background: radial-gradient(circle, rgba(52, 114, 251, 0.8) 0%, rgba(52, 114, 251, 0.3) 35%, rgba(52, 114, 251, 0) 70%);
  position: absolute;
  top: 114%;
  right: 37%;
  min-height: 500px;
  min-width: 500px;
  border-radius: 50%;
  opacity: 0.3;
  @include media-breakpoint-down(md) {
    display: none;
  }
}
