@import "../../styles/colors.module";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";

.bitblex {
  margin-top: 170px;
}

.imageBorder {
  border-bottom: 1px solid $secondary-color;
  @include media-breakpoint-down(md) {
    img {
      width: 100%;
    }
  }
}

.whatsapp {
  border-radius: 50px;
  padding: 12px 11px 12px 40px;
  position: absolute;
  bottom: -8%;
  right: 7%;

  img {
    position: absolute;
    height: 63px;
    top: -3px;
    left: -13px;
  }
}

.whatsappGradiant {
  background: radial-gradient(circle, rgba(30, 199, 95, 0.7) 0%, rgba(30, 199, 95, 0.1) 40%, rgba(130, 199, 95, 0) 75%);
  position: absolute;
  top: -20%;
  right: -3%;
  min-height: 350px;
  min-width: 500px;
  border-radius: 50%;
  opacity: 0.3;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}