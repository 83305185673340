@import "colors.module";

* {
  direction: rtl;
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  margin: 0;
  overflow-x: hidden;
  background-color: #181c1f;
  color: #ffffff;
  direction: ltr !important;
  height: 100vh !important;
  font-size: 16px !important;
}

#root {
  height: 100vh !important;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 450px;
  }

  .footer {
    max-width: 1250px;
  }
}

@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 540px;
  }

  .footer {
    max-width: 1250px;
  }
}

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 720px;
  }

  .footer {
    max-width: 1250px;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0 0.5em;
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }

  .footer {
    max-width: 1250px;
  }
}

@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }

  .footer {
    max-width: 1250px;
  }
}

.border-radius-7 {
  border-radius: 7px !important;
}

.box-shadow {
  box-shadow: -3px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
}

.cursor-pointer {
  cursor: pointer;
}

.icon-shadow {
  box-shadow: -3px 4px 11px #ecf1f4;
}

.border-radius-70 {
  border-radius: 50%;
}

hr {
  color: #ecf1f4;
}

.ant-input-group {
  border-radius: 5px !important;
}

.ant-input-group-addon, .ant-input-number-group-addon {
  border: 0 !important;
  border-radius: 5px !important;
}

.footer {
  width: 100%;
  padding-left: var(--bs-gutter-x, 0.75rem);
  padding-right: var(--bs-gutter-x, 0.75rem);
  margin-left: auto;
  margin-right: auto;
}

.ant-modal {
  text-align: right !important;
}

.ant-modal-content {
  height: 100%;
  border: 1px solid #124368 !important;
  border-bottom-width: 7px !important;
  background-color: #131619 !important;
  color: #ffffff;
  padding: 10px !important;
}

.ant-modal-header {
  display: flex;
  border: none;
  border-radius: 5px 5px 0 0 !important;
  background-color: #131619 !important;
  color: #ffffff !important;
  margin: 5px 10px;

  .ant-modal-title {
    color: #ffffff !important;
  }
}

.ant-modal-close-icon {
  color: #ffffff;
}

.ant-modal-footer {
  display: none;
}

.ant-modal-body {
  padding: 5px;
}

.ant-breadcrumb-separator {
  color: #fff !important;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

.ant-layout-sider-zero-width-trigger {
  top: 87px;
  line-height: 35px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  border-left: 0;
}

.overflow-y {
  overflow-y: auto;
}

.ant-form-item-label > label {
  color: white;
}

.ant-form-item {
  margin-bottom: 15px !important;
}

.ant-picker.ant-picker-borderless {
  background-color: unset;
}

a {
  text-decoration: none;
}

.ant-collapse-expand-icon {
  color: $white
}

.faqPanel {
  .ant-collapse-content {
    background-color: unset !important;

    .ant-collapse-content-box {
      background-color: #2b3135;
    }
  }

  .ant-collapse-content > .ant-collapse-content-box {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}

.ant-carousel .slick-slider .slick-track {
  transform: translate3d(0, 0, 0) !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background: #ccc;

  &:hover {
    background: $primary-color;
  }
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}

.avatar-uploader {
  background-color: white;
  padding: 4px 10px;
  border-radius: 5px;
}

.ant-input-number-rtl {
  font-family: Arial;
}

.top-10 {
  top: 10px !important;
}

.ant-radio-button-wrapper {
  margin: 0 5px;
  padding: 0 5px;
  border-radius: 0.75rem !important;
  border: none !important;
  background-color: transparent;
  color: #7F99FB;

  &::before {
    display: none !important;
  }

  &:hover {
    background: rgba(127, 153, 251, 0.12) !important;
    color: #7F99FB !important;
  }
}

.ant-radio-button-wrapper-checked {
  background: #2D3748 !important;
  color: #7F99FB !important;
}

.ant-tooltip-inner {
  background-color: $black;
}

.ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph > li {
  background-color: #d9d9d926 !important;
}

.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  background-color: #d9d9d926 !important;
}

.ant-skeleton .ant-skeleton-header .ant-skeleton-avatar {
  background-color: #d9d9d926 !important;
}

.ant-pagination-item {
  color: #fff;
  border-color: $primary-color !important;
}

.ant-pagination-item-active {
  color: #000;
}

.ant-pagination-next, .ant-pagination-item-link, .ant-pagination-item-link-icon, .ant-pagination-item-ellipsis {
  color: #fff !important;
}

[class^="ant-space"] {
  font-size: 1em;
}

.ant-steps-item-wait {
  .ant-steps-item-icon {
    border-color: white !important;

    .ant-steps-icon {
      color: white !important;
    }
  }
}

.ant-steps-icon {
  -moz-font-feature-settings: "ss02" !important;
  -webkit-font-feature-settings: "ss02" !important;
  font-feature-settings: "ss02" !important;
}

.ant-notification {
  z-index: 99999999999999;
}

.reactour__close-button {
  top: 15px !important;
  right: 12px !important;
}

.reactour__popover {
  color: #000 !important;

  div {
    button {
      transform: rotateY(180deg);
    }
  }
}

.ant-input-number-input {
  font-feature-settings: "ss02" !important;
}

.ant-collapse-expand-icon {
  justify-content: center !important;
  height: 100% !important;
}

.ant-collapse-header {
  align-items: center !important;
  justify-content: center !important;
}

.inviteFriendPanel {
  .ant-collapse-content {
    background: unset !important;
    border: none !important;

    .ant-collapse-content-box {
      background: linear-gradient(270deg, rgba(52, 114, 251, 0.2) 0%, rgba(52, 114, 251, 0) 0%);
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }
}

.videoContainer {
  video {
    border-radius: 20px;
  }
}

.ant-input-clear-icon {
  color: white !important;
}
