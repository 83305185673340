@import "../../../styles/colors.module";
@import "../../../styles/button.module";

.creditCard {
  border-radius: 15px !important;
  text-shadow: 0 1px 1px rgb(0 0 0 / 30%);
  box-shadow: 0 1px 10px 1px rgb(0 0 0 / 30%);
  border: none;
}

.backgroundGradiant {
  background-image: linear-gradient(43deg, #2a2a2a, rgb(74, 74, 74));
}

.cardNumbers {
  margin: 40px 25px 10px;
  font-size: 23px;
}

.addCardBtn {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: color .20s ease-in-out, background-color .20s ease-in-out, border-color .20s ease-in-out, box-shadow .20s ease-in-out;
  @extend .btnSecondary
}

.selectedFavWithdrawal {
  border: 3px solid $primary-color;
}

.bankLogoContainer {
  background-color: white;
  border-radius: 7px;
  padding: 5px;
}
