.container {
  margin: 6em 0 0 0;
}

@media (max-width: 768px) {
  .container {
    margin: 3em 0 0 0;
  }
}

.startNow {
  border: 1px solid #fff;
  padding: 5px;
  background-color: #262626;
  border-radius: 10px;

  input::placeholder {
    color: #f0f0f0;
  }
}

.startNowInput {
  border: none;
}

.cardExchange {
  background-color: rgba(255, 255, 255, 0.2);
}