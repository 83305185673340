@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/variables";

.mainContainer {
  margin: 6em 0 0 0;
}

.firstCard {
  background-color: rgba(33, 35, 36, 1);
  padding: 25px;
  z-index: 2;
  border-radius: 30px;
  border: 1px solid #404040;
}

.secondCard {
  background: linear-gradient(90deg, rgba(14, 15, 15, 0.36) 0%, rgba(68, 73, 75, 0.6) 100%);
  padding: 55px 0 25px 0;
  z-index: 1;
  position: absolute;
  bottom: -130px;
  width: 100%;
  border-radius: 30px;
  margin: 20px 0;
  @include media-breakpoint-down(md) {
    bottom: -290px;
  }
}

.img {
  height: 100%;
  width: 60%;
  transform: translate(0, -125px) scale(1.5);
}
