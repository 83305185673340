@import "../../styles/colors.module";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";

.container {
  margin: 6em 0 0 0;
  z-index: 3;
  @include media-breakpoint-down(md) {
    margin: 3em 0 0 0;
  }
}

.tlgIcon {
  position: absolute;
  right: -15%;
  bottom: -55%;
  z-index: 2;

  @include media-breakpoint-down(md) {
   display: none;
  }
}

.gradiant {
  background: radial-gradient(circle, rgba(52, 114, 251, 0.9) 20%, rgba(81, 135, 253, 0.3) 40%, rgba(104, 151, 255, 0.001) 75%);
  position: absolute;
  top: 9%;
  left: 26%;
  min-height: 700px;
  min-width: 700px;
  border-radius: 50%;
  opacity: 0.3;
  z-index: 1;
  @include media-breakpoint-down(md) {
    min-height: 400px;
    min-width: 400px;
    top: -5%;
    left: 10%;
  }
}

.gradiant2 {
  background: radial-gradient(circle, rgba(180, 94, 255, 1) 0%, rgba(180, 94, 255, 0.01) 60%, rgba(180, 94, 255, 0) 100%);
  position: absolute;
  top: -5%;
  left: 39%;
  min-height: 700px;
  min-width: 700px;
  border-radius: 50%;
  opacity: 0.3;
  z-index: 1;
  @include media-breakpoint-down(md) {
    min-height: 400px;
    min-width: 400px;
    top: -5%;
    left: 10%;
  }
}

.videoContainer {
  border: 3px solid #404040;
  border-radius: 20px;
  height: 300px;
}

.img{
  @include media-breakpoint-down(md) {
    width: 75%;
  }
}