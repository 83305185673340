@import "../../styles/colors.module";

//.colSm0 {
//  display: unset;
//}

@media (max-width: 766px) {
  .colSm0 {
    display: none !important;
  }
}

.left137 {
  left: 124px;
}

.left0 {
  left: 0;
}

.leftChildrenOpenAnimation {
  animation: leftChildrenOpenKeyFrame 150ms ease-in-out forwards;
}

@keyframes leftChildrenOpenKeyFrame {
  0% {
    opacity: 0;
    transform: translate(-100%)
  }
  100% {
    opacity: 1;
    transform: translate(0%)
  }
}

.rightChildrenOpenAnimation {
  animation: rightChildrenOpenKeyFrame 150ms ease-in-out forwards;
}

@keyframes rightChildrenOpenKeyFrame {
  0% {
    transform: translate(100%)
  }
  100% {
    transform: translate(0%)
  }
}

.leftChildrenCloseAnimation {
  animation: leftChildrenCloseKeyFrame 150ms ease-in-out forwards;
}

@keyframes leftChildrenCloseKeyFrame {
  0% {
    transform: translate(0%);
  }
  100% {
    transform: translate(-100%);
  }
}

.rightChildrenCloseAnimation {
  animation: rightChildrenCloseKeyFrame 150ms ease-in-out forwards;
}

@keyframes rightChildrenCloseKeyFrame {
  0% {
    transform: translate(0%);
  }
  100% {
    transform: translate(100%);
  }
}

.sideLeft {
  background-color: #131619;
  border-top: 5px solid;
  transition: all 0.4s ease-in;
}

.blur {
  background-color: #131619;
  opacity: 0.9;
}

.closeIcon {
  position: absolute;
  top: 15px;
  color: $white;
  z-index: 3;
  transition: transform 0.5s;

  &:hover {
    transform: rotateZ(180deg);
  }
}

.left15 {
  left: 15px;
}

.right15 {
  right: 15px;
}