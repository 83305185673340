@import "../../styles/colors.module";

.introCard {
  min-height: 55vh;
  box-shadow: 0 14px 26px -12px #16181c, 0 4px 23px 0 #16181c, 0 8px 10px -5px #16181c;

  p {
    line-height: 1.75;
  }
}
