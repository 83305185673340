@import '~normalize.css/normalize.css';
@import '~antd/dist/reset.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~animate.css';
//@import 'bootstrap/scss/bootstrap';
@import 'bootstrap-rtl';
@import 'font/fonts';
@import 'global';
@import 'button.module';
@import 'react-responsive-carousel/lib/styles/carousel.min.css';
