@import "../../styles/colors.module";

.navbar {
  border-bottom: 1px solid #2f3a40;
  height: 65px;
}

.toggler {
  height: unset;
  width: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown {
  background-color: $tertiary-color;
  color: $white !important;
  padding: 0;
  border: 1px solid $primary-color;
  transition: all .1s;
}

.ant-dropdown-menu-item-divider {
  color: $tertiary-light-color !important;
}

.help {
  background-color: #212324 !important;
  border: 1px solid #404040;
  z-index: 9000 !important;
  border-radius: 20px !important;
}

.helpVideoContainer {
  border: 3px solid #404040;
  border-radius: 20px;
  height: 100px;
  width: 200px;
}

.activeNavItem {
  border-bottom: 3px solid #6997FB;
  background: linear-gradient(0deg, rgba(52,114,251,0.3) 0%, rgba(52,114,251,0) 50%);
}