@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

//$primary-color: #3287c8;
$primary-color: #0d6efd;
$secondary-color: #10d078;
$secondary-dark-color: #172e28;
$tertiary-color: #1b2126;
$tertiary-light-color: #2b3135;
$body-bg: #181c1f;
$border-color: #ecf1f4;
$inputs-color: #f6f7f8;
$dark-color: #131619;
$semi-muted: #CCCCCC;

:export {
  primary-color: $primary-color
}

.primaryColor {
  color: $primary-color;
}

.secondaryColor {
  color: $secondary-color;
}

.tertiaryColor {
  color: $tertiary-color;
}

.tertiaryLightColor {
  color: $tertiary-light-color;
}

.secondaryDarkColor {
  color: $secondary-dark-color;
}

.bgPrimaryColor {
  background-color: $primary-color;
}

.bgSecondaryColor {
  background-color: $secondary-color;
}

.bgTertiaryColor {
  background-color: $tertiary-color;
}

.bgTertiaryLightColor {
  background-color: $tertiary-light-color;
}

.bgSecondaryDarkColor {
  background-color: $secondary-dark-color;
}

.inputs {
  background-color: $inputs-color !important;
}

.footer-bg, .footer-container {
  background-color: $dark-color;
}

.bodyBg {
  background-color: $body-bg;
}

.text-semi-muted {
  color: $semi-muted
}
