@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/variables";

.mainContainer {
  margin-top: 170px;
  @include media-breakpoint-down(md) {
    margin-top: 300px;
  }
}

.videoContainer {
  border: 3px solid #404040;
  border-radius: 20px;
  height: 300px;
  //width: 540px;
}
