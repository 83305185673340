@import "../../styles/colors.module";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";

.container {
  margin: 6em 0 0 0;
  @include media-breakpoint-down(md) {
    margin: 3em 0 0 0;
  }
  z-index: 5;
  position: relative;
}

.form {
  border-right: 3px solid #707070;
  border-radius: 5px;
  padding: 0 50px 0 0;
  @include media-breakpoint-down(lg) {
    padding: 50px 0 0 0;
    border: none;
  }
}

.borderDash {
  border-bottom: 3px dashed $gray-700;
  padding: 15px 0 15px 0;
}

.input {
  border: 1px solid rgba(255, 255, 255, 0.6);
  color: white;

  input {
    color: white;
  }

  &:hover {
    border: 1px solid rgba(255, 255, 255, 1) !important;
  }
}

.gradiant {
  background: radial-gradient(circle, rgba(52, 114, 251, 0.7) 0%, rgba(81, 135, 253, 0.6) 30%, rgba(104, 151, 255, 0) 70%);
  position: absolute;
  top: 8%;
  left: 30%;
  min-height: 700px;
  min-width: 700px;
  border-radius: 50%;
  opacity: 0.3;
  @include media-breakpoint-down(md) {
    min-height: 500px;
    min-width: 500px;
    top: -10%;
    left: -0%;
  }
}