@import "../../../styles/colors.module";

.searchInput {
  background-color: $tertiary-color !important;
}

.searchInputAddOn {
  background-color: $inputs-color;
  color: $tertiary-color !important;
  text-align: center;
  border-radius: 5px;
}

.defaultValue {
  border: 1px solid $gray-700;
}

.selectedDefaultValue {
  border: 3px solid $primary-color;
}

.creditCard {
  border: 1px solid $border-color
}

.selectedCreditCard {
  border: 3px solid $secondary-color;
}