@import "../../styles/colors.module";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";

.container {
  margin: 6em 0 0 0;
  @include media-breakpoint-down(md) {
    margin: 3em 0 0 0;
  }
}

.mainContainer {
  margin: 6em 0 0 0;
}

.img1 {
  position: absolute;
  left: 0;
  z-index: 2;
}

.img2 {
  position: absolute;
  right: 11%;
  top: 0;
  z-index: 2;
}

.img3 {
  position: absolute;
  left: 28%;
  bottom: -30%;
  z-index: 2;
}

.firstCard {
  background-color: rgba(33, 35, 36, 1);
  padding: 25px;
  z-index: 2;
  border-radius: 30px;
  border: 1px solid #404040;
}

.secondCard {
  background: linear-gradient(90deg, rgba(14, 15, 15, 0.36) 0%, rgba(68, 73, 75, 0.6) 100%);
  padding: 25px 0;
  width: 100%;
  border-radius: 30px;
  margin: 125px 0 0 0;
  z-index: 2;

  @include media-breakpoint-down(md) {
    margin: 35px 0 0 0;
  }
}

.supportMargin {
  margin-top: 175px;
  @include media-breakpoint-down(md) {
    margin-top: 375px;
  }
}

.gradiant {
  background: radial-gradient(circle, rgb(52, 114, 251) 0%, rgba(81, 135, 253, 0.5) 50%, rgba(104, 151, 255, 0.001) 72%);
  position: absolute;
  top: 8%;
  left: 13%;
  min-height: 700px;
  min-width: 700px;
  border-radius: 50%;
  opacity: 0.3;
  z-index: 1;
  @include media-breakpoint-down(md) {
    min-height: 300px;
    min-width: 300px;
    top: -10%;
    left: -0%;
  }
}

.gradiant2 {
  background: radial-gradient(circle, rgba(180, 94, 255, 1) 0%, rgba(180, 94, 255, 0.08) 52%, rgba(180, 94, 255, 0) 100%);
  position: absolute;
  top: 8%;
  left: 30%;
  min-height: 700px;
  min-width: 700px;
  border-radius: 50%;
  opacity: 0.3;
  z-index: 2;
  @include media-breakpoint-down(md) {
    min-height: 400px;
    min-width: 400px;
    top: -5%;
    left: -0%;
  }
}

.whyBitBlexGradiant {
  background: radial-gradient(circle, rgb(118, 99, 219) 0%, rgba(118, 99, 219, 0.5) 51%, rgba(118, 99, 219, 0) 70%);
  position: absolute;
  top: 108%;
  left: 28%;
  min-height: 500px;
  min-width: 500px;
  border-radius: 50%;
  opacity: 0.3;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.whyBitBlexGradiant2 {
  background: radial-gradient(circle, rgb(118, 99, 219) 0%, rgba(118, 99, 219, 0.4) 40%, rgba(118, 99, 219, 0) 71%);
  position: absolute;
  top: 129%;
  right: 12%;
  min-height: 500px;
  min-width: 500px;
  border-radius: 50%;
  opacity: 0.3;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.videoContainer {
  border: 3px solid #404040;
  border-radius: 20px;
  height: 300px;
  //width: 540px;
}
