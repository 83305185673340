@import "../../styles/colors.module";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";

.helpList {
  background: linear-gradient(135deg, rgba(68, 73, 75, 0.4) 0%, rgba(33, 34, 35, 0) 50%);
  border-radius: 30px;
  border: 1px solid #404040;
}

.videoContainer {
  border: 3px solid #404040;
  border-radius: 20px;
  height: 300px;
  //width: 540px;
}

.selectedHelp {
  background: linear-gradient(270deg, rgba(52, 114, 251, 0.5) 0%, rgba(52, 114, 251, 0) 100%);
  border-right: 4px solid #3472FB;
}