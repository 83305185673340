@font-face {
  font-family: 'dana VF';
  src: url('DanaVF.woff') format('woff-variations'),
    /* will be the standard and works in Safari now */
  url('DanaVF.woff') format('woff'),
  url('DanaVF.woff2') format('woff2');
  /* for the other supporting browsers */
  font-display: fallback;
}

@font-face {
  font-family: dana;
  src: url('Dana-Regular.woff') format('woff');
}

@font-face {
  font-family: dana;
  src: url('Dana-Bold.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: danaFaNum;
  src: url('DanaFaNum-Regular.woff') format('woff'),
  url('DanaFaNum-Regular.woff2') format('woff2');
  font-weight: 700;
}

*, body, .ant-form, .ant-form-item, [class^="ant-col"], .ant-input, .ant-input-number-input, [class^="ant-row"], [class^="ant-input"] {
  font-family: dana, tahoma;
}

.dana-font {
  font-family: dana, tahoma !important;
}

@supports (font-variation-settings: normal) {
  *, body, .ant-form, .ant-form-item, [class^="ant-col"], .ant-input, .ant-input-number-input, [class^="ant-row"], [class^="ant-input"] {
    font-family: 'dana VF', tahoma;
  }
}

.ant-input-number .ant-input-number-input {
  font-family: unset;
}

.dana-fa-number {
  font-family: danaFaNum;
}

canvas {
  font-family: danaFaNum !important;
}

.ss02, .ant-notification-notice-content, .ant-input {
  -moz-font-feature-settings: "ss02" !important;
  -webkit-font-feature-settings: "ss02" !important;
  font-feature-settings: "ss02" !important;
}

$font-size-extra-tiny: 0.5em;
$font-size-tiny: 0.75em;
$font-size-small: 0.875em;
$font-size-normal: 1em;
$font-size-mini: 1.2562em;
$font-size-large: 1.562em;
$font-size-xlarge: 1.875em;
$font-size-2xlarge: 2.5em;

.fontWeightBold {
  font-variation-settings: "wght" 700
}

.fontSizeExtraTiny {
  font-size: $font-size-extra-tiny;
}

.fontSizeTiny {
  font-size: $font-size-tiny;
}

.fontSizeSmall {
  font-size: $font-size-small;
}

.fontSizeNormal {
  font-size: $font-size-normal;
}

.fontSizeMini {
  font-size: $font-size-mini;
}

.fontSizeLarge {
  font-size: $font-size-large;
}

.fontSizeXlarge {
  font-size: $font-size-xlarge;
}

.fontSize2xlarge {
  font-size: $font-size-2xlarge;
}

