@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/variables";

.mainContainer {
  border: 2px solid #404040;
  background: rgb(52, 114, 251);
  background: linear-gradient(45deg, rgba(52, 114, 251, 0.3) 0%, rgba(52, 114, 251, 0) 100%);
  border-radius: 30px;
  margin: 6em 0 0 0;
}

.img {
  height: 100%;
  width: 45%;
  transform: scale(1.75);
  @include media-breakpoint-down(md) {
    transform: scale(1.25);
  }
}
